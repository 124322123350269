import '../../styles/recording.css'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { GLOBALTYPES } from '../../redux/actions/GlobalTypes';
import { update_credit } from '../../redux/actions/PaymentAction';
import { postDataAPI } from '../../utils/FetchData';
import { create_record_activity, get_user_credits, update_user_activity } from '../../redux/actions/UserAction';
import Loading from '../Loader/Loading';
import ProShortcut from './ProShortcut';
import { Helmet } from 'react-helmet';
import InfoIcon from '../../svgicon/InfoIcon';
import { info } from '../../common/popover';
import { uploadAudio } from '../../utils/UploadData';
const OPENAI_KEY = process.env.REACT_APP_OPENAI_KEY

export default function ProHome() {
    const token = localStorage.getItem('repeatMeToken')
    const credits = useSelector(state => state.user?.credits)
    let previousPrompt = useSelector(state => state.user?.prompt)
    let previousPromptTwo = useSelector(state => state.user?.promptTwo)
    let previousPromptThree = useSelector(state => state.user?.promptThree)
    const isDarkTheme = useSelector(state => state.theme?.theme)
    const creditsLoading = useSelector(state => state.user?.loading)

    const [text, setText] = useState('')
    const [correctGrammarText, setCorrectGrammarText] = useState('')
    const [correctSummaryText, setCorrectSummaryText] = useState('')

    const [copyMessage, setCopyMessage] = useState('');
    const [grammarCopyMessage, setGrammarCopyMessage] = useState("");
    const [summaryCopyMessage, setSummaryCopyMessage] = useState('');
    const [onlyTranscriptMessage, setOnlyTranscriptMessage] = useState(false);
    const onlyTranscriptMessageRef = useRef(onlyTranscriptMessage);

    const [visiblePopoverId, setVisiblePopoverId] = useState(null);

    const handleMouseEnter = (id) => {
        setVisiblePopoverId(id);
    };

    const handleMouseLeave = () => {
        setVisiblePopoverId(null);
    };



    const [isRecording, setIsRecording] = useState(false);
    const [timer, setTimer] = useState(0);
    const timerRef = useRef(timer);
    const maxRecordingTime = credits / 2 > 300 ? 300 : Math.floor(credits / 2); // 5 minutes in seconds
    const mediaRecorderRef = useRef(null);
    const audioChunksRef = useRef([]);
    const dispatch = useDispatch()
    // const [textLoading, setTextLoading] = useState(false)
    // const [prompt, setPrompt] = useState("'comma' is ',' and 'dot' is '.' CAR-T cell, DALLE-3,")
    const [prompt, setPrompt] = useState("")
    const [promptTwo, setPromptTwo] = useState("")
    const [promptThree, setPromptThree] = useState("")

    useEffect(() => {
        let userActivityMessage = "Pro Home"
        dispatch(update_user_activity(userActivityMessage, token))
    }, [dispatch, token]);

    useEffect(() => {

        if (token) {
            dispatch(get_user_credits(token))
            if (previousPrompt) {
                setPrompt(previousPrompt)
            }
            if (previousPromptTwo) {
                setPromptTwo(previousPromptTwo)
            }
            if (previousPromptThree) {
                setPromptThree(previousPromptThree)
            }
        }
    }, [dispatch, token, previousPrompt, previousPromptTwo, previousPromptThree])

    useEffect(() => {
        onlyTranscriptMessageRef.current = onlyTranscriptMessage;
    }, [onlyTranscriptMessage]);



    const toggleRecording = () => {
        if (!isRecording || timer >= maxRecordingTime) {
            startRecording();
        } else {
            stopRecording();
        }
    };

    const transcribeAudio = useCallback(async (audioBlob, timeParameter) => {
        // formData.append('file', audioBlob, 'recording.mp3');
        const formData = new FormData();
        formData.append('file', audioBlob, 'recording.wav');
        let model = 'whisper-1';
        formData.append('model', model)
        formData.append('prompt', prompt)



        try {
            if (credits > 1) {

                // setTextLoading(true)

                const response = await fetch('https://api.openai.com/v1/audio/transcriptions', {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${OPENAI_KEY}`, // Replace with your API key
                        // 'Content-Type': 'multipart/form-data'
                    },
                    body: formData
                });

                if (response) {
                    const result = await response.json();
                    dispatch(await update_credit(timeParameter * 2, token))
                    setText(result?.text)
                    setTimer(0)
                    // setTextLoading(false)

                    if (!onlyTranscriptMessageRef.current) {
                        // setText(whisperResult.text); // Update your UI or state as needed
                        const chatResponse = await fetch('https://api.openai.com/v1/chat/completions', {
                            method: 'POST',
                            headers: {
                                'Authorization': `Bearer ${OPENAI_KEY}`,
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                model: "gpt-4",
                                messages: [
                                    // { "role": "system", "content": "Answer step by step and with examples with sample data" },
                                    { "role": "system", "content": promptTwo },
                                    { "role": "user", "content": result.text }
                                ]
                            })
                        });

                        const chatResult = await chatResponse.json();
                        setCorrectGrammarText(chatResult?.choices[0]?.message?.content)



                        const chatResponse2 = await fetch('https://api.openai.com/v1/chat/completions', {
                            method: 'POST',
                            headers: {
                                'Authorization': `Bearer ${OPENAI_KEY}`,
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                model: "gpt-4",
                                messages: [
                                    // { "role": "system", "content": "Answer step by step and with examples with sample data" },
                                    { "role": "system", "content": promptThree },
                                    { "role": "user", "content": result.text }
                                ]
                            })
                        });

                        const chatResult2 = await chatResponse2.json();
                        setCorrectSummaryText(chatResult2?.choices[0]?.message?.content)

                        let userActivityMessage = "Recording Pro"
                        dispatch(update_user_activity(userActivityMessage, token))
                        const responseData = await uploadAudio(formData);
                        let body = {
                            type: "Pro",
                            audio: responseData?.data?.audioUrl,
                            credits: timeParameter * 2,
                            text: {
                                block: result?.text,
                                blockTwo: chatResult?.choices[0]?.message?.content,
                                blockThree: chatResult2?.choices[0]?.message?.content
                            }
                        }
                        dispatch(create_record_activity(body, token))

                    }
                    else {
                        // setTextLoading(false)
                        let userActivityMessage = "Recording Pro"
                        dispatch(update_user_activity(userActivityMessage, token))
                        setOnlyTranscriptMessage(false)
                        const responseData = await uploadAudio(formData);
                        let body = {
                            type: "Pro",
                            audio: responseData?.data?.audioUrl,
                            credits: timeParameter * 2,
                            text: {
                                block: result?.text,
                            }
                        }
                        dispatch(create_record_activity(body, token))
                    }
                }
                else {
                    // setTextLoading(false)

                    dispatch({
                        type: GLOBALTYPES.ALERT,
                        payload: {
                            error: 'Something went wrong'
                        }
                    })
                    setTimer(0);
                }

            }
            else {
                dispatch({
                    type: GLOBALTYPES.ALERT,
                    payload: {
                        error: 'Please Purchase Credits To Continue.'
                    }
                })
                setTimer(0);
            }
        }
        catch (error) {
            setTimer(0);
            console.error('There was an error calling the Whisper API', error);
            dispatch({
                type: GLOBALTYPES.ALERT,
                payload: {
                    error: 'Something Went Wrong'
                }
            })
        }
    }, [credits, dispatch, prompt, token, promptTwo, promptThree]);

    const startRecording = useCallback(async () => {

        try {

            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            mediaRecorderRef.current = new MediaRecorder(stream);

            mediaRecorderRef.current.ondataavailable = (e) => {
                audioChunksRef.current.push(e.data);
            };

            mediaRecorderRef.current.onstop = async () => {
                const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
                await transcribeAudio(audioBlob, timerRef.current);
                audioChunksRef.current = [];
            };

            mediaRecorderRef.current.start(1000);
            setIsRecording(true);
        } catch (err) {
            console.error('An error occurred during recording:', err);
            dispatch({
                type: GLOBALTYPES.ALERT,
                payload: {
                    error: err.message
                }
            })

        }

        // Start recording logic
    }, [dispatch, transcribeAudio]);

    const stopRecording = useCallback(() => {
        // Stop recording logic
        mediaRecorderRef.current.stop();
        const stream = mediaRecorderRef.current?.stream;
        if (stream) {
            stream.getTracks().forEach(track => track.stop());
        }
        setIsRecording(false);
    }, []); // Empty dependency array, function will only be created once

    const copyToClipboard = useCallback((textToCopy) => {
        navigator.clipboard.writeText(textToCopy).then(() => {
            setCopyMessage('successfully copied!');
            setTimeout(() => setCopyMessage(''), 2000);
        }).catch(err => {
            console.error('Could not copy text: ', err);
        });
    }, []); // Empty dependency array

    const copyToClipboardGrammar = useCallback((textToCopy) => {
        navigator.clipboard.writeText(textToCopy).then(() => {
            setGrammarCopyMessage('successfully copied!');
            setTimeout(() => setGrammarCopyMessage(''), 2000);
        }).catch(err => {
            console.error('Could not copy text: ', err);
        });
    }, []); // Empty dependency array

    const copyToClipboardSummary = useCallback((textToCopy) => {
        navigator.clipboard.writeText(textToCopy).then(() => {
            setSummaryCopyMessage('successfully copied!');
            setTimeout(() => setSummaryCopyMessage(''), 2000);
        }).catch(err => {
            console.error('Could not copy text: ', err);
        });
    }, []);
    useEffect(() => {
        const handleKeyPress = (event) => {
            if (document.activeElement.tagName === 'INPUT') {
                return;
            }
            if (event.key === 'a' || event.key === 'A') {
                startRecording();

            } else if (event.key === 'S' || event.key === 's' || event.key === 'space') {
                stopRecording();

            }
            else if (event.key === 'c' || event.key === 'C') {
                copyToClipboard(text);

            } else if (event.key === 'g' || event.key === 'G') {
                copyToClipboardGrammar(correctGrammarText);
            } else if (event.key === 'h' || event.key === 'H') {
                copyToClipboardSummary(correctGrammarText);
            } else if (event.key === 'z' || event.key === 'Z') {
                setOnlyTranscriptMessage(true);
                stopRecording();
            }
        };

        window.addEventListener('keydown', handleKeyPress);
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [isRecording, startRecording, stopRecording, text, correctGrammarText, copyToClipboard, copyToClipboardGrammar, copyToClipboardSummary]);

    useEffect(() => {
        let interval = null;

        if (isRecording) {
            interval = setInterval(() => {
                setTimer(prevTimer => {
                    if (prevTimer < maxRecordingTime) {
                        timerRef.current = prevTimer + 1;
                        return prevTimer + 1;
                    } else {
                        stopRecording();
                        timerRef.current = prevTimer;
                        return prevTimer;
                    }
                    // const newTimer = prevTimer < maxRecordingTime ? prevTimer + 1 : prevTimer;
                    // timerRef.current = newTimer; // Update the ref
                    // return newTimer;
                });
            }, 1000);
        } else {
            clearInterval(interval);
            // setTimer(0)

            // Reset timer when not recording
        }

        return () => clearInterval(interval);
    }, [isRecording, maxRecordingTime, stopRecording]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        // setPrompt(prompt)
        // if (!prompt) {
        //     dispatch({
        //         type: GLOBALTYPES.ALERT,
        //         payload: {
        //             error: 'Please Enter Prompt'
        //         }
        //     })
        //     return
        // }
        try {
            await postDataAPI('user/update_prompt', { prompt }, token).then(() => {
                dispatch({
                    type: GLOBALTYPES.ALERT,
                    payload: {
                        success: 'Prompt Updated Successfully'
                    }
                })
            })
        } catch (error) {
            dispatch({
                type: GLOBALTYPES.ALERT,
                payload: {
                    error: 'Prompt Updation Failed'
                }
            })

        }
    }

    const handleSubmitTwo = async (e) => {
        e.preventDefault();
        // setPrompt(prompt)
        // if (!promptTwo) {
        //     dispatch({
        //         type: GLOBALTYPES.ALERT,
        //         payload: {
        //             error: 'Please Enter Prompt'
        //         }
        //     })
        //     return
        // }
        try {
            await postDataAPI('user/update_prompt_two', { promptTwo }, token).then(() => {
                dispatch({
                    type: GLOBALTYPES.ALERT,
                    payload: {
                        success: 'Prompt Updated Successfully'
                    }
                })
            })
        } catch (error) {
            dispatch({
                type: GLOBALTYPES.ALERT,
                payload: {
                    error: 'Prompt Updation Failed'
                }
            })

        }
    }
    const handleSubmitThree = async (e) => {
        e.preventDefault();
        // setPrompt(prompt)
        // if (!promptThree) {
        //     dispatch({
        //         type: GLOBALTYPES.ALERT,
        //         payload: {
        //             error: 'Please Enter Prompt'
        //         }
        //     })
        //     return
        // }
        try {
            await postDataAPI('user/update_prompt_three', { promptThree }, token).then(() => {
                dispatch({
                    type: GLOBALTYPES.ALERT,
                    payload: {
                        success: 'Prompt Updated Successfully'
                    }
                })
            })
        } catch (error) {
            dispatch({
                type: GLOBALTYPES.ALERT,
                payload: {
                    error: 'Prompt Updation Failed'
                }
            })

        }
    }


    return (
        <>
            <Helmet>
                <title>Pro - SpeechToText.live</title>
                <meta name="description" content="Welcome back! Convert speech to text effortlessly with our advanced transcription service." />
                <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16639027107"></script>
                <script>
                    {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'AW-16639027107');
        `}
                </script>
                <script>
                    {
                        `gtag('event', 'conversion', {
                        'send_to': 'AW-16639027107/0dm0CL-2qsEZEKPPjf49',
                    'value': 1.0,
                    'currency': 'INR'
    });`
                    }

                </script>
            </Helmet>
            {
                creditsLoading ? <Loading /> :
                    <div className=''>

                        <div className='flex flex-col gap-2 items-center w-full ' >

                            <div className='grid grid-cols-1 md:grid-cols-1 gap-2' >
                                <div className=''
                                >
                                    <ProShortcut />


                                </div>
                                {/* </div> */}
                                <div className={`bg-gray-100 max-w-[800px] px-[30px] rounded-lg flex justify-center items-center flex-col gap-y-[9px] relative py-[15px] ${isDarkTheme ? 'box-showdow-css-dark' : 'box-showdow-css'} dark:bg-[#111111]`}>
                                    {/* <div className='text-center dark:text-white mx-auto'> Credits : {minute} min {second} sec</div> */}

                                    <form onSubmit={handleSubmit} className="w-[320px] sm:w-[350px] flex items-center justify-center gap-x-2">
                                        {visiblePopoverId === 1 && (
                                            <div className="absolute left-0 top-[80px] z-[99999] inline-block w-64 text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800">
                                                <div className="px-3 py-2 bg-gray-100 border-b border-gray-200 rounded-t-lg dark:border-gray-600 dark:bg-gray-700">
                                                    <h3 className="font-semibold text-gray-900 dark:text-white">Info</h3>
                                                </div>
                                                <div className="px-3 py-2">
                                                    <p>{info[0].text}</p>
                                                </div>
                                                <div data-popper-arrow></div>
                                            </div>
                                        )}
                                        <InfoIcon
                                            className={`w-5 h-5 cursor-pointer ${isDarkTheme ? 'text-white' : 'text-black'}`}
                                            color={isDarkTheme ? 'white' : 'black'}
                                            onMouseEnter={() => handleMouseEnter(1)}
                                            onMouseLeave={handleMouseLeave}
                                        />
                                        <div className="input-container text-black w-full">
                                            <input type="search" className="text-black" autoComplete="off" autoCorrect="off" id="search"
                                                style={{ color: "black" }}
                                                placeholder="Type difficult spellings: DALLE3, CRISPR, Joaquin, Saoirse, or etc"
                                                onChange={(e) => setPrompt(e.target.value)}
                                                value={prompt}
                                            />
                                            <button type="submit" className="button">Submit</button>
                                        </div>
                                    </form>


                                    <form onSubmit={handleSubmitTwo} className="w-[320px] sm:w-[350px] flex items-center justify-center gap-x-2">
                                        {visiblePopoverId === 2 && (
                                            <div className="absolute left-0 top-[130px] inline-block w-64 text-sm text-gray-500 z-[999999] transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800">
                                                <div className="px-3 py-2 bg-gray-100 border-b border-gray-200 rounded-t-lg dark:border-gray-600 dark:bg-gray-700">
                                                    <h3 className="font-semibold text-gray-900 dark:text-white">Info</h3>
                                                </div>
                                                <div className="px-3 py-2">
                                                    <p>{info[1].text}</p>
                                                </div>
                                                <div data-popper-arrow></div>
                                            </div>
                                        )}
                                        <InfoIcon
                                            className={`w-5 h-5 cursor-pointer ${isDarkTheme ? 'text-white' : 'text-black'}`}
                                            color={isDarkTheme ? 'white' : 'black'}
                                            onMouseEnter={() => handleMouseEnter(2)}
                                            onMouseLeave={handleMouseLeave}
                                        />
                                        <div className="input-container text-black w-full">
                                            <input type="search" className="text-black" autoComplete="off" autoCorrect="off" id="search"
                                                style={{ color: "black" }}
                                                placeholder="Type prompt for second block"
                                                onChange={(e) => setPromptTwo(e.target.value)}
                                                value={promptTwo}
                                            />
                                            <button type="submit" className="button">Submit</button>
                                        </div>
                                    </form>

                                    <form onSubmit={handleSubmitThree} className="w-[320px] sm:w-[350px] flex items-center justify-center gap-x-2">
                                        {visiblePopoverId === 3 && (
                                            <div className="absolute left-0 top-[180px] z-[999999] inline-block w-64 text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800">
                                                <div className="px-3 py-2 bg-gray-100 border-b border-gray-200 rounded-t-lg dark:border-gray-600 dark:bg-gray-700">
                                                    <h3 className="font-semibold text-gray-900 dark:text-white">Info</h3>
                                                </div>
                                                <div className="px-3 py-2">
                                                    <p>{info[2].text}</p>
                                                </div>
                                                <div data-popper-arrow></div>
                                            </div>
                                        )}
                                        <InfoIcon
                                            className={`w-5 h-5 cursor-pointer ${isDarkTheme ? 'text-white' : 'text-black'}`}
                                            color={isDarkTheme ? 'white' : 'black'}
                                            onMouseEnter={() => handleMouseEnter(3)}
                                            onMouseLeave={handleMouseLeave}
                                        />
                                        <div className="input-container text-black w-full">
                                            <input type="search" className="text-black" autoComplete="off" autoCorrect="off" id="search"
                                                style={{ color: "black" }}
                                                placeholder="Type prompt for third block"
                                                onChange={(e) => setPromptThree(e.target.value)}
                                                value={promptThree}
                                            />
                                            <button type="submit" className="button">Submit</button>
                                        </div>
                                    </form>

                                    <div className="flex justify-center items-center gap-5 outline-none border-none">
                                        <button className="bg-white outline-none rounded-full shadow-lg relative flex gap-5 p-5" onClick={toggleRecording}>
                                            <svg className="z-[999] outline-none" fill="black" xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 512 512">
                                                <path d="M439.5 236c0-11.3-9.1-20.4-20.4-20.4s-20.4 9.1-20.4 20.4c0 70-64 126.9-142.7 126.9-78.7 0-142.7-56.9-142.7-126.9 0-11.3-9.1-20.4-20.4-20.4s-20.4 9.1-20.4 20.4c0 86.2 71.5 157.4 163.1 166.7v57.5H212c-11.3 0-20.4 9.1-20.4 20.4 0 11.3 9.1 20.4 20.4 20.4h88c11.3 0 20.4-9.1 20.4-20.4 0-11.3-9.1-20.4-20.4-20.4h-23.6v-57.5c91.6-9.3 163.1-80.5 163.1-166.7z" />
                                                <path d="M256 323.5c51 0 92.3-41.3 92.3-92.3V103.3c0-51-41.3-92.3-92.3-92.3s-92.3 41.3-92.3 92.3v127.9c0 51 41.3 92.3 92.3 92.3zm-52.3-220.2c0-28.8 23.5-52.3 52.3-52.3s52.3 23.5 52.3 52.3v127.9c0 28.8-23.5 52.3-52.3 52.3s-52.3-23.5-52.3-52.3V103.3z" />
                                            </svg>
                                        </button>
                                        {isRecording && (
                                            <span className="text-black bg-white px-3 py-[7px] shadow-lg rounded-md">
                                                {`${Math.floor(timer / 60).toString().padStart(2, '0')}:${(timer % 60).toString().padStart(2, '0')}`}
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className='flex flex-col sm:flex-row gap-5 justify-center items-center w-full px-4 py-5'>
                                {text && <div className={`bg-gray-100  max-w-[380px] sm:w-[380px] p-[20px] rounded-lg flex justify-center items-center flex-col gap-5 py-5 text-black  ${isDarkTheme ? 'box-showdow-css-dark' : 'box-showdow-css'} dark:bg-[#111111] dark:text-white`} style={{ minHeight: '150px', maxWidth: '500px' }}> {/* Adjust the minHeight and maxWidth as needed */}
                                    {text}

                                </div>
                                }
                                {text && <div className={`bg-gray-100  max-w-[380px] sm:w-[380px] p-[20px] rounded-lg flex justify-center items-center flex-col gap-5 py-5 text-black  ${isDarkTheme ? 'box-showdow-css-dark' : 'box-showdow-css'} dark:bg-[#111111] dark:text-white`}
                                    style={{ minHeight: '150px', maxWidth: '500px' }}> {/* Adjust the minHeight and maxWidth as needed */}
                                    {correctGrammarText}
                                </div>
                                }
                                {text && <div className={`bg-gray-100  max-w-[380px] sm:w-[380px] p-[20px] rounded-lg flex justify-center items-center flex-col gap-5 py-5 text-black  ${isDarkTheme ? 'box-showdow-css-dark' : 'box-showdow-css'} dark:bg-[#111111] dark:text-white`}
                                    style={{ minHeight: '150px', maxWidth: '500px' }}> {/* Adjust the minHeight and maxWidth as needed */}
                                    {correctSummaryText}
                                </div>
                                }
                            </div>
                        </div>
                        {text.length > 0 && copyMessage && (
                            <div style={{ position: 'fixed', top: 10, right: 10, backgroundColor: '#23272F', padding: '10px', borderRadius: '5px', color: 'white' }}>
                                Text Copied to clipboard
                            </div>
                        )}
                        {correctGrammarText.length > 0 && grammarCopyMessage && (
                            <div style={{ position: 'fixed', top: 10, right: 10, backgroundColor: '#23272F', padding: '10px', borderRadius: '5px', color: 'white' }}>
                                Correct Grammer Text Copied to clipboard
                            </div>
                        )}
                        {correctSummaryText.length > 0 && summaryCopyMessage && (
                            <div style={{ position: 'fixed', top: 10, right: 10, backgroundColor: '#23272F', padding: '10px', borderRadius: '5px', color: 'white' }}>
                                Summary Text Copied to clipboard
                            </div>
                        )}

                    </div >
            }

        </>



    )
}
