import '../../styles/recording.css'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { GLOBALTYPES } from '../../redux/actions/GlobalTypes';
import { update_credit } from '../../redux/actions/PaymentAction';
import { postDataAPI } from '../../utils/FetchData';
import Shortcut from './Shortcut';
import { create_record_activity, get_user_credits, update_user_activity, USER_TYPES } from '../../redux/actions/UserAction';
import Loading from '../Loader/Loading';
import { Helmet } from 'react-helmet';
import { info } from '../../common/popover';
import InfoIcon from '../../svgicon/InfoIcon';
import { uploadAudio } from '../../utils/UploadData';
const OPENAI_KEY = process.env.REACT_APP_OPENAI_KEY

export default function BasicHome() {
    const token = localStorage.getItem('repeatMeToken')
    const credits = useSelector(state => state.user?.credits)
    let previousPrompt = useSelector(state => state.user?.prompt)
    const isDarkTheme = useSelector(state => state.theme?.theme)
    const creditsLoading = useSelector(state => state.user?.loading)
    const [isVisible, setIsVisible] = useState(true);
    const [text, setText] = useState('')
    const [copyMessage, setCopyMessage] = useState('');
    const [isRecording, setIsRecording] = useState(false);
    const [timer, setTimer] = useState(0);
    const timerRef = useRef(timer);
    const maxRecordingTime = credits > 300 ? 300 : credits; // 5 minutes in seconds
    const mediaRecorderRef = useRef(null);
    const audioChunksRef = useRef([]);
    const dispatch = useDispatch()
    const [textLoading, setTextLoading] = useState(false)
    // const [prompt, setPrompt] = useState("'comma' is ',' and 'dot' is '.' CAR-T cell, DALLE-3,")
    const [prompt, setPrompt] = useState("")
    const [visiblePopover, setVisiblePopover] = useState(false);


    let flag = true
    useEffect(() => {
        if (flag) {
            let userActivityMessage = "Beginner Home"
            dispatch(update_user_activity(userActivityMessage, token))
            flag = false
        }
    }, [dispatch, token]);


    useEffect(() => {

        if (token) {
            dispatch(get_user_credits(token))
            if (previousPrompt) {
                setPrompt(previousPrompt)
            }
        }
    }, [dispatch, token, previousPrompt])

    const handleMouseEnter = (id) => {
        setVisiblePopover(true);
    };

    const handleMouseLeave = () => {
        setVisiblePopover(false);
    };
    const handleClose = () => {
        setIsVisible(false);
    };


    const toggleRecording = () => {
        if (!isRecording || timer >= maxRecordingTime) {
            startRecording();
        } else {
            stopRecording();
        }
    };

    const transcribeAudio = useCallback(async (audioBlob, timeParameter) => {
        // formData.append('file', audioBlob, 'recording.mp3');
        const formData = new FormData();
        formData.append('file', audioBlob, 'recording.wav');
        let model = 'whisper-1';
        formData.append('model', model)
        formData.append('prompt', prompt)



        try {
            if (credits > 0) {

                setTextLoading(true)

                const response = await fetch('https://api.openai.com/v1/audio/transcriptions', {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${OPENAI_KEY}`, // Replace with your API key
                        // 'Content-Type': 'multipart/form-data'
                    },
                    body: formData
                });

                if (response) {
                    const result = await response.json();
                    dispatch(await update_credit(timeParameter, token))
                    setText(result?.text)
                    setTimer(0)
                    setTextLoading(false)
                    let userActivityMessage = "Recording Beginner"
                    dispatch(update_user_activity(userActivityMessage, token))
                    const responseData = await uploadAudio(formData);
                    let body = {
                        type: "Beginner",
                        audio: responseData?.data?.audioUrl,
                        credits: timeParameter,
                        text: {
                            block: result?.text
                        }
                    }
                    dispatch(create_record_activity(body, token))

                }
                else {
                    setTextLoading(false)

                    dispatch({
                        type: GLOBALTYPES.ALERT,
                        payload: {
                            error: 'Something went wrong'
                        }
                    })
                    setTimer(0);
                }

            }
            else {
                dispatch({
                    type: GLOBALTYPES.ALERT,
                    payload: {
                        error: 'Please Purchase Credits.'
                    }
                })
                setTimer(0);
            }
        }
        catch (error) {
            setTimer(0);
            console.error('There was an error calling the Whisper API', error);
            dispatch({
                type: GLOBALTYPES.ALERT,
                payload: {
                    error: 'Something Went Wrong'
                }
            })
        }
    }, [credits, dispatch, prompt, token]);

    const startRecording = useCallback(async () => {

        try {

            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            mediaRecorderRef.current = new MediaRecorder(stream);

            mediaRecorderRef.current.ondataavailable = (e) => {
                audioChunksRef.current.push(e.data);
            };

            mediaRecorderRef.current.onstop = async () => {
                const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
                await transcribeAudio(audioBlob, timerRef.current);
                audioChunksRef.current = [];
            };

            mediaRecorderRef.current.start(1000);
            setIsRecording(true);
        } catch (err) {
            console.error('An error occurred during recording:', err);
            dispatch({
                type: GLOBALTYPES.ALERT,
                payload: {
                    error: err.message
                }
            })

        }

        // Start recording logic
    }, [dispatch, transcribeAudio]);

    const stopRecording = useCallback(() => {
        // Stop recording logic
        mediaRecorderRef.current.stop();
        const stream = mediaRecorderRef.current?.stream;
        if (stream) {
            stream.getTracks().forEach(track => track.stop());
        }
        setIsRecording(false);
    }, []);

    const copyToClipboard = (textToCopy) => {
        navigator.clipboard.writeText(textToCopy).then(() => {
            setCopyMessage('suceessfully copied!');
            setTimeout(() => setCopyMessage(''), 2000);
        }).catch(err => {
            console.error('Could not copy text: ', err);
        });
    };
    useEffect(() => {
        const handleKeyPress = (event) => {
            if (document.activeElement.tagName === 'INPUT') {
                return;
            }
            if (event.key === 'a' || event.key === 'A') {
                startRecording();

            } else if (event.key === 'S' || event.key === 's' || event.key === 'space') {
                stopRecording();

            }
            else if (event.key === 'c' || event.key === 'C') {
                copyToClipboard(text);
            }
        };

        window.addEventListener('keydown', handleKeyPress);
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [isRecording, startRecording, stopRecording, text]);

    useEffect(() => {
        let interval = null;

        if (isRecording) {
            interval = setInterval(() => {
                setTimer(prevTimer => {
                    if (prevTimer < maxRecordingTime) {
                        timerRef.current = prevTimer + 1;
                        return prevTimer + 1;
                    } else {
                        stopRecording();
                        timerRef.current = prevTimer;
                        return prevTimer;
                    }
                    // const newTimer = prevTimer < maxRecordingTime ? prevTimer + 1 : prevTimer;
                    // timerRef.current = newTimer; // Update the ref
                    // return newTimer;
                });
            }, 1000);
        } else {
            clearInterval(interval);
            // setTimer(0)

            // Reset timer when not recording
        }

        return () => clearInterval(interval);
    }, [isRecording, maxRecordingTime, stopRecording]);
    const handleChange = (e) => {
        setPrompt(
            e.target.value,
        );
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        // setPrompt(prompt)
        // if (!prompt) {
        //     dispatch({
        //         type: GLOBALTYPES.ALERT,
        //         payload: {
        //             error: 'Please Enter Prompt'
        //         }
        //     })
        //     return
        // }
        try {
            await postDataAPI('user/update_prompt', { prompt }, token).then(() => {
                dispatch({
                    type: GLOBALTYPES.ALERT,
                    payload: {
                        success: 'Prompt Updated Successfully'
                    }
                })
            })
        } catch (error) {
            dispatch({
                type: GLOBALTYPES.ALERT,
                payload: {
                    error: 'Prompt Updation Failed'
                }
            })

        }
    }

    const handleProNavigation = (e) => {
        e.preventDefault()

        dispatch({
            type: USER_TYPES.HOME,
            payload: {
                toggle: true // Toggle the value
            }
        });
    }

    return (
        <>
            <Helmet>
                <title>Beginner - SpeechToText.live</title>
                <meta name="description" content="Welcome back! Convert speech to text effortlessly with our advanced transcription service." />
                <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16639027107"></script>
                <script>
                    {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'AW-16639027107');
        `}
                </script>
                <script>
                    {
                        `gtag('event', 'conversion', {
                        'send_to': 'AW-16639027107/0dm0CL-2qsEZEKPPjf49',
                    'value': 1.0,
                    'currency': 'INR'
    });`
                    }

                </script>
            </Helmet>
            {
                creditsLoading ? <Loading /> :
                    <div className=''>

                        <div className='flex flex-col gap-8 items-center w-full mt-8' >
                            <div className='mt-[-32px] w-full lg:hidden'>

                                {isVisible && (
                                    <div id="sticky-banner" tabIndex="-1" className="start-0 z-50 flex justify-between w-full p-4 border-b border-gray-200 bg-gray-50 dark:bg-black dark:border-gray-600">
                                        <div className="flex items-center mx-auto">
                                            <p className="flex items-center text-sm gap-x-2 font-normal text-gray-500 dark:text-gray-400">
                                                <span className={`text-sm ${isDarkTheme ? 'text-white ' : ''}`}>
                                                    Try our Pro mode for free.</span>
                                                <button className=' text-black 
                                                bg-[#7BF1F2] py-[4px] px-3 rounded-[5px]' onClick={handleProNavigation}>Click here</button>
                                            </p>
                                        </div>
                                        <div className="flex items-center">
                                            <button onClick={handleClose} type="button" className="flex-shrink-0 inline-flex justify-center w-7 h-7 items-center text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 dark:hover:bg-gray-600 dark:hover:text-white">
                                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                                </svg>
                                                <span className="sr-only">Close banner</span>
                                            </button>
                                        </div>
                                    </div>
                                )
                                }
                            </div>

                            <div className='grid grid-cols-1 md:grid-cols-1 gap-8' >

                                {/* <div className='w-full grid grid-cols-1 h-full max-w-[370px] bg-white border border-gray-200 rounded-lg shadow-md py-[10px] box-showdow-css dark:bg-black'
                        > */}
                                <div className=''
                                >
                                    <Shortcut />


                                </div>
                                {/* </div> */}
                                <div className={`bg-gray-100 max-w-[800px] px-[30px]  rounded-lg flex justify-center items-center flex-col gap-y-4 py-[30px] ${isDarkTheme ? 'box-showdow-css-dark' : 'box-showdow-css'} dark:bg-[#111111]`} >
                                    {/* <div className='text-center dark:text-white mx-auto'> Credits : {minute} min {second} sec</div> */}

                                    <form onSubmit={handleSubmit} className='w-[320px] sm:w-[350px] flex items-center justify-center gap-x-2'>
                                        {visiblePopover && (
                                            <div className="absolute left-0 top-[90px] inline-block w-64 text-sm text-gray-500 z-[999999] transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800">
                                                <div className="px-3 py-2 bg-gray-100 border-b border-gray-200 rounded-t-lg dark:border-gray-600 dark:bg-gray-700">
                                                    <h3 className="font-semibold text-gray-900 dark:text-white">Info</h3>
                                                </div>
                                                <div className="px-3 py-2">
                                                    <p>{info[0].text}</p>
                                                </div>
                                                <div data-popper-arrow></div>
                                            </div>
                                        )}
                                        <InfoIcon
                                            className={`w-5 h-5 cursor-pointer ${isDarkTheme ? 'text-white' : 'text-black'}`}
                                            color={isDarkTheme ? 'white' : 'black'}
                                            onMouseEnter={() => handleMouseEnter(2)}
                                            onMouseLeave={handleMouseLeave}
                                        />
                                        <div className="input-container text-black w-full">
                                            <input type="search" className='text-black' autoComplete='off' autoCorrect='off' id="search" style={{ color: "black" }} placeholder="Type difficult spellings: DALLE3, CRISPR, Joaquin, Saoirse, or etc" onChange={handleChange} value={prompt} />
                                            <button type="submit" className="button">Submit</button>
                                        </div>
                                    </form>

                                    <div className='flex justify-center items-center gap-5 outline-none border-none' >
                                        <button className='bg-white  outline-none rounded-full shadow-lg relative flex  gap-5 p-5' onClick={toggleRecording}>

                                            <svg className='z-[999] outline-none'
                                                fill='black'
                                                xmlns="http://www.w3.org/2000/svg"
                                                width={24}
                                                height={24}
                                                viewBox="0 0 512 512"
                                            >
                                                <path d="M439.5 236c0-11.3-9.1-20.4-20.4-20.4s-20.4 9.1-20.4 20.4c0 70-64 126.9-142.7 126.9-78.7 0-142.7-56.9-142.7-126.9 0-11.3-9.1-20.4-20.4-20.4s-20.4 9.1-20.4 20.4c0 86.2 71.5 157.4 163.1 166.7v57.5H212c-11.3 0-20.4 9.1-20.4 20.4 0 11.3 9.1 20.4 20.4 20.4h88c11.3 0 20.4-9.1 20.4-20.4 0-11.3-9.1-20.4-20.4-20.4h-23.6v-57.5c91.6-9.3 163.1-80.5 163.1-166.7z" />
                                                <path d="M256 323.5c51 0 92.3-41.3 92.3-92.3V103.3c0-51-41.3-92.3-92.3-92.3s-92.3 41.3-92.3 92.3v127.9c0 51 41.3 92.3 92.3 92.3zm-52.3-220.2c0-28.8 23.5-52.3 52.3-52.3s52.3 23.5 52.3 52.3v127.9c0 28.8-23.5 52.3-52.3 52.3s-52.3-23.5-52.3-52.3V103.3z" />
                                            </svg>
                                        </button>
                                        {isRecording && <span className=" text-black bg-white px-3 py-[7px] shadow-lg rounded-md">
                                            {`${Math.floor(timer / 60).toString().padStart(2, '0')}:${(timer % 60).toString().padStart(2, '0')}`}
                                        </span>}
                                    </div>


                                </div>
                            </div>

                            {text && !textLoading && <div className={`bg-gray-100  max-w-[380px] sm:w-[380px] p-[20px] rounded-lg flex justify-center items-center flex-col gap-5 py-5 text-black  ${isDarkTheme ? 'box-showdow-css-dark' : 'box-showdow-css'} dark:bg-[#111111] dark:text-white`} >

                                {text}

                            </div>
                            }
                            {textLoading && <div className={`bg-gray-100  max-w-[380px] sm:w-[380px] p-[20px] rounded-lg flex justify-center items-center flex-col gap-5 py-5 text-black  ${!isDarkTheme ? 'box-showdow-css' : ''} dark:bg-[#111111] dark:text-white`} >

                                {/* <Circles
                            height="50"
                            width="50"
                            color="rgb(147 51 234)"
                            ariaLabel="circles-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                        /> */}

                            </div>
                            }
                        </div>
                        {text.length > 0 && copyMessage && (
                            <div style={{ position: 'fixed', top: 10, right: 10, backgroundColor: '#23272F', padding: '10px', borderRadius: '5px', color: 'white' }}>
                                Text Copied to clipboard
                            </div>
                        )}

                    </div >
            }

        </>



    )
}
